import React from "react"
import {OrderDeliveryInfo} from "../../../app/interfaces/salesInterfaces"
import OrderLayout from "./OrderLayout"
import OrderLayoutHeading from "./OrderLayoutHeading"
import styles from "./styles.module.scss"
import {convertToApiFormat} from "../../../app/utils/helpers"

type Props = {
  deliveryInfo: OrderDeliveryInfo
}

const OrderDeliveryLayout: React.FC<Props> = ({deliveryInfo}) => {
  function extractStreetAndHouse(address: string) {
    if (!address.includes("[DIVIDER]")) {
      return address
    }

    const parts = address.split("[DIVIDER]")
    const street = parts[3] || "" // Индекс 3 — это street
    const house = parts[4] || "" // Индекс 4 — это house

    return house ? `${street}, ${house}` : street
  }
  return (
    <OrderLayout>
      <OrderLayoutHeading>Способ получения товара</OrderLayoutHeading>
      <div className={styles.customerInfo}>
        <div className={styles.orderLayoutItem}>
          <p>Тип доставки:</p> <span>{deliveryInfo.delivery_type?.delivery_type_name}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>Склад:</p> <span>{deliveryInfo.warehouse?.warehouse_name || "-"}</span>
        </div>
        <div className={styles.orderLayoutItem}>
          <p>UT код склада:</p> <span>{deliveryInfo.warehouse?.warehouse_code_ut || "-"}</span>
        </div>
        {!!deliveryInfo?.delivery_interval_start && (
          <div className={styles.orderLayoutItem}>
            <p>Начало тайм слота:</p> <span>{convertToApiFormat(new Date(deliveryInfo.delivery_interval_start))}</span>
          </div>
        )}
        {!!deliveryInfo?.delivery_interval_end && (
          <div className={styles.orderLayoutItem}>
            <p>Конец тайм слота:</p> <span>{convertToApiFormat(new Date(deliveryInfo.delivery_interval_end))}</span>
          </div>
        )}
        {deliveryInfo.delivery_type?.delivery_type_code !== "pickup" && (
          <>
            <div className={styles.orderLayoutItem}>
              <p>Город:</p> <span>{deliveryInfo.warehouse?.city?.city_name_ru || "-"}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Улица и номер дома/здания:</p> <span>{extractStreetAndHouse(deliveryInfo.street_and_house)}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Кв/офис:</p> <span>{deliveryInfo.full_address}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Подъезд:</p> <span>{deliveryInfo.entrance}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Этаж:</p> <span>{deliveryInfo.floor}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Домофон:</p> <span>{deliveryInfo.flat}</span>
            </div>
            <div className={styles.orderLayoutItem}>
              <p>Комментарий:</p> <span>{deliveryInfo.comment}</span>
            </div>
          </>
        )}
      </div>
    </OrderLayout>
  )
}

export default OrderDeliveryLayout
