export const AppRoutes = {
  signIn: "/",
  home: "/",
  users: "/users",
  operations: "/operations",
  permissions: "/permissions",
  roles: "/roles",
  resources: "/resources",
  modelStockCreation: "/products/create",
  modelStockDetails: "/products/detail",
  orders: "/orders",
  warehouseDetails: "/warehouses",
  promotions: "/promotions",
  promotionCreation: "/promotions/create",
  news: "/news",
  newsCreation: "/news/create",
  promotionsNew: "/promotions_control/promotions",
  promotionsNewCreation: "/promotions_control/promotions/create",
  promotionsV1Creation: "/promotions_control/promotions/create/v1/create",
  promotionsV2Creation: "/promotions_control/promotions/create/v2/create",
  promotionsV3Creation: "/promotions_control/promoti${AppRoutes.modelStockDetails}ons/create/v3/create",
  promotionsV4Creation: "/promotions_control/promotions/create/v4/create",
  promotionsV5Creation: "/promotions_control/promotions/create/v5/create",
  promotionsV6Creation: "/promotions_control/promotions/create/v6/create",
  promotionsV7Creation: "/promotions_control/promotions/create/v7/create",
  promotionsV8Creation: "/promotions_control/promotions/create/v8/create",
  promotionsV9Creation: "/promotions_control/promotions/create/v9/create",
  promotionsV10Creation: "/promotions_control/promotions/create/v10/create",
  attributesCreation: "/characteristics/attributes/create",
  optionsCreation: "/characteristics/options/create",
  colorsCreation: "/characteristics/colors/create",
  modelsCreation: "/characteristics/models/create",
  brandCreations: "/characteristics/brands/create",
  categoriesCreations: "/characteristics/categories/create",
  templatesCreation: "/templates/create",
  serviceCreation: "/services/service/create",
  serviceGroupCreation: "/services/serviceGroup/create",
  attributes: "/characteristics/attributes",
  attributeGroups: "/characteristics/attribute_groups",
  attributeGroupsCreation: "/characteristics/attribute_groups/create",
  colors: "/characteristics/colors",
  options: "/characteristics/options",
  categories: "/characteristics/categories",
  models: "/characteristics/models",
  brands: "/characteristics/brands",
  templates: "/templates",
  services: "/services",
  servicesCreations: "/services/service",
  vacancies: "/vacancy",
  vacanciesCreation: "/vacancy/create",
  locations: "/locations",
  locationsCreation: "/locations/create",
  files: "/files",
  fileGroupsCreation: "/files/create",
  promo_coupons: "/promo_coupons",
  promo_couponsCreation: "/promo_coupons/create",
  promocodes: "/promocodes",
  promocodesCreation: "/promocodes/create",
  cashback: "/cashback",
  cashbackCreation: "/cashback/create",
  seoSettings: "/seo_settings",
  seoCreation: "/seo_settings/create",
  preorderCreation: "/preorder/create/content",
  preorderApplications: "/preorder/create/applications",
  digitalServices: "/digital_service_packages",
  stickers: "/stickers",
  inventory: "/inventorization",
  homepage: "/homepage",
  homepageAuctionBlock: "/homepage/create/auction_block",
  homepageSecondaryBlock: "/homepage/create/secondary_block",
  push_notification: "/push_notification",
  loansCalculator: "/loans_calculator",
  exceptionLoanCreation: "/loans_calculator/exception_loan/create",
  basicLoanCreation: "/loans_calculator/basic_loan/create",
  faq: "/faq",
  crossSale: "/cross_sale",
  crossSaleModel: "/cross_sale/model",
  devicePicker: "/device_picker",
  cashCollection: "/cash_collection",
}
