import http from "../utils/http"
import {
  ProductAttribute,
  ProductAttributeKey,
  ProductBrand,
  ProductChannelRelation,
  ProductCityRelation,
  ProductColorCreate,
  ProductImage,
  ProductMainInformationCreate,
  ProductModel,
  ProductOption,
  ProductOptionKey,
  ProductWarehouseRelation,
  ProductTemplateAttributeKeyCreate,
  ProductTemplateCreate,
  ProductTemplateOptionKeyCreate,
  WarehouseChannelRelation,
  WarehouseRelation,
  WarehouseCityRelation,
  UserWarehouseRelation,
  UserWarehouseCityRelation,
  UserWarehouseChannelRelation,
  SimilarProduct,
  ServiceType,
  Service,
  UserReport,
  ChannelReport,
  WarehouseReport,
  CityReport,
  CategoryBrand,
  InstallmentPercent,
  TelecomOperator,
  Order,
  OrderDetail,
  OrderCustomerInfo,
  OrderProduct,
  OrderHistory,
  OrderPaymentInfo,
  OrderAdditionalInfo,
  ProductAttributeGroup,
  CardType,
  Store,
  ServiceCostPrice,
  ServiceGroup,
  PublicOffer,
  StoreWeekSchedule,
  IKeyVendor,
} from "../interfaces/salesInterfaces"
import {FileUploadInterface} from "../interfaces/layoutInterfaces"
import {PromiseResponse, PromiseTableResponse} from "../interfaces/http"
import {BeelineChangePhoneNumber, Tele2ChangePhoneNumber} from "../interfaces/onlineIntegrationInterfaces"

const BASE_URL = `${process.env.REACT_APP_BASE_API_URL}/sale/api/v1`

const getAllPaymentTypes = () => {
  return http.get(`${BASE_URL}/payment-type/all`)
}

const getAllPaymentStatuses = () => {
  return http.get(`${BASE_URL}/common/order-status/all`)
}

const transferCategory = (fromId: number, toId: number) => {
  return http.put(`${BASE_URL}/model/category`, null, {
    params: {
      from_category_id: fromId,
      to_category_id: toId,
    },
  })
}

const getOptionsList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/options/items`, {params: {page, size, ...filterData}})
}

const getAllOptions = () => {
  return http.get(`${BASE_URL}/options/all`)
}

const getOption = (optionId: number) => {
  return http.get(`${BASE_URL}/options`, {params: {option_id: optionId}})
}

const createOption = (data: ProductOption) => {
  return http.post(`${BASE_URL}/options`, data)
}

const updateOption = (data: ProductOption) => {
  return http.put(`${BASE_URL}/options`, data, {params: {option_id: data.option_id}})
}

const deleteOption = (optionId: number) => {
  return http.delete(`${BASE_URL}/options`, {params: {option_id: optionId}})
}

const getOptionsKeyList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/option-key/items`, {params: {page, size, ...filterData}})
}

const getAllOptionKeys = () => {
  return http.get(`${BASE_URL}/option-key/all`)
}

const getOptionKey = (optionKeyId: number) => {
  return http.get(`${BASE_URL}/option-key`, {params: {option_key_id: optionKeyId}})
}

const createOptionKey = (data: ProductOptionKey) => {
  return http.post(`${BASE_URL}/option-key`, data)
}

const updateOptionKey = (data: ProductOptionKey) => {
  return http.put(`${BASE_URL}/option-key`, data, {params: {option_key_id: data.option_key_id}})
}

const updateOptionKeySelective = (data: ProductOptionKey) => {
  return http.patch(`${BASE_URL}/option-key`, data, {
    params: {
      option_key_id: data.option_key_id,
    },
  })
}

const deleteOptionKey = (optionKeyId: number) => {
  return http.delete(`${BASE_URL}/option-key`, {params: {option_key_id: optionKeyId}})
}

const getTemplatesList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/template/items`, {params: {page, size, ...filterData}})
}

const getAllTemplates = () => {
  return http.get(`${BASE_URL}/template/all`)
}

const getTemplate = (templateId: number) => {
  return http.get(`${BASE_URL}/template`, {params: {template_id: templateId}})
}

const getTemplateByCategory = (categoryId: number) => {
  return http.get(`${BASE_URL}/template`, {params: {category_id: categoryId}})
}

const getTemplateByModel = (modelId: number) => {
  return http.get(`${BASE_URL}/template`, {params: {model_id: modelId}})
}

const createTemplate = (data: ProductTemplateCreate) => {
  return http.post(`${BASE_URL}/template`, data)
}

const updateTemplate = (data: ProductTemplateCreate) => {
  return http.put(`${BASE_URL}/template`, data, {params: {template_id: data.template_id}})
}

const deleteTemplate = (templateId: number) => {
  return http.delete(`${BASE_URL}/template`, {params: {template_id: templateId}})
}

const createTemplateAttributeKey = (data: ProductTemplateAttributeKeyCreate) => {
  return http.post(`${BASE_URL}/template-attribute-key`, data)
}

const createTemplateOptionKey = (data: ProductTemplateOptionKeyCreate) => {
  return http.put(`${BASE_URL}/template/add-option-key`, data)
}

const getAttributesList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/attribute/items`, {params: {page, size, ...filterData}})
}

const getAllAttributes = () => {
  return http.get(`${BASE_URL}/attribute/all`)
}

const createAttribute = (data: ProductAttribute) => {
  return http.post(`${BASE_URL}/attribute`, data)
}

const getAttribute = (attributeId: number) => {
  return http.get(`${BASE_URL}/attribute`, {params: {attribute_id: attributeId}})
}

const updateAttribute = (data: ProductAttribute) => {
  return http.put(`${BASE_URL}/attribute`, data, {params: {attribute_id: data.attribute_id}})
}

const updateAttributeSelective = (data: ProductAttribute) => {
  return http.patch(`${BASE_URL}/attribute`, data, {
    params: {
      attribute_id: data.attribute_id,
    },
  })
}

const deleteAttribute = (attributeId: number) => {
  return http.delete(`${BASE_URL}/attribute`, {params: {attribute_id: attributeId}})
}

const getAttributeKeysList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/attribute-key/items`, {params: {page, size, ...filterData}})
}

const getAllAttributeKeys = () => {
  return http.get(`${BASE_URL}/attribute-key/all`)
}

const getAttributeKey = (attributeKeyId: number) => {
  return http.get(`${BASE_URL}/attribute-key`, {params: {attribute_key_id: attributeKeyId}})
}

const createAttributeKey = (data: ProductAttributeKey) => {
  return http.post(`${BASE_URL}/attribute-key`, data)
}

const updateAttributeKey = (data: ProductAttributeKey) => {
  return http.put(`${BASE_URL}/attribute-key`, data, {params: {attribute_key_id: data.attribute_key_id}})
}

const updateAttributeKeySelective = (data: ProductAttributeKey) => {
  return http.patch(`${BASE_URL}/attribute-key`, data, {
    params: {
      attribute_key_id: data.attribute_key_id,
    },
  })
}

const deleteAttributeKey = (attributeId: number) => {
  return http.delete(`${BASE_URL}/attribute-key`, {params: {attribute_key_id: attributeId}})
}

const getAttributeGroupsList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/attribute-group/items`, {params: {page, size, ...filterData}})
}

const getAllAttributeGroups = () => {
  return http.get(`${BASE_URL}/attribute-group/all`)
}

const getAttributeGroup = (attributeGroupId: number) => {
  return http.get(`${BASE_URL}/attribute-group`, {
    params: {
      attribute_group_id: attributeGroupId,
    },
  })
}

const createAttributeGroup = (data: ProductAttributeGroup) => {
  return http.post(`${BASE_URL}/attribute-group`, data)
}

const updateAttributeGroup = (data: ProductAttributeGroup) => {
  return http.put(`${BASE_URL}/attribute-group`, data, {
    params: {attribute_group_id: data.attribute_group_id},
  })
}

const deleteAttributeGroup = (attributeGroupId: number) => {
  return http.delete(`${BASE_URL}/attribute-group`, {params: {attribute_group_id: attributeGroupId}})
}

const getColorList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/color/items`, {params: {page, size, ...filterData}})
}

const getAllColors = () => {
  return http.get(`${BASE_URL}/color/all`)
}

const createColor = (data: ProductColorCreate) => {
  return http.post(`${BASE_URL}/color`, data)
}

const getColor = (colorId: number) => {
  return http.get(`${BASE_URL}/color`, {params: {color_id: colorId}})
}

const updateColor = (data: ProductColorCreate) => {
  return http.put(`${BASE_URL}/color`, data, {params: {color_id: data.color_id}})
}

const updateColorSelective = (data: ProductColorCreate) => {
  return http.patch(`${BASE_URL}/color`, data, {
    params: {
      color_id: data.color_id,
    },
  })
}

const deleteColor = (colorId: number) => {
  return http.delete(`${BASE_URL}/color`, {params: {color_id: colorId}})
}

const getStockList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/stock/items`, {params: {page, size, ...filterData}})
}

const getStoreList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/store/items`, {params: {page, size, ...filterData}})
}

const updateStoreSchedule = (storeId: number, data: Array<StoreWeekSchedule>) => {
  return http.post(`${BASE_URL}/store/schedule-update`, data, {
    params: {
      store_id: storeId,
    },
  })
}

const publishStore = (storeId: number, state: boolean) => {
  return http.put(
    `${BASE_URL}/store/is-active`,
    {
      is_online_published: state,
    },
    {
      params: {
        store_id: storeId,
      },
    },
  )
}

const updateStoreSelective = (data: Store) => {
  return http.patch(`${BASE_URL}/store`, data, {
    params: {
      store_id: data.store_id,
    },
  })
}

const getAllStores = (): PromiseResponse<Array<Store>> => {
  return http.get(`${BASE_URL}/store/all`)
}

const getUniqueStockList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/stock/unique-items`, {params: {page, size, ...filterData}})
}

const getAllStocks = (skuName?: string) => {
  return http.get(`${BASE_URL}/stock/all`, {params: {sku_name: skuName}})
}

const getModelList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/model/items-v2`, {params: {page, size, ...filterData}})
}

const getAllModels = (modelName?: string | null, modelType?: number): PromiseResponse<ProductModel[]> => {
  return http.get(`${BASE_URL}/model/all`, {
    params: {
      model_name: modelName,
      model_type: modelType,
    },
  })
}

const getModel = (modelId: number) => {
  return http.get(`${BASE_URL}/model`, {params: {model_id: modelId}})
}

const createModel = (data: ProductModel) => {
  return http.post(`${BASE_URL}/model`, data)
}

const updateModel = (data: ProductModel) => {
  return http.put(`${BASE_URL}/model`, data, {params: {model_id: data.model_id}})
}

const updateModelSelective = (data: ProductModel, modelId: number) => {
  return http.patch(`${BASE_URL}/model`, data, {
    params: {
      model_id: modelId,
    },
  })
}

const deleteModel = (modelId: number) => {
  return http.delete(`${BASE_URL}/model`, {params: {model_id: modelId}})
}

const getBrandList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/brand/items`, {params: {page, size, ...filterData}})
}

const getBrandsByCategory = (categoryId: number) => {
  return http.get(`${BASE_URL}/brand/by-category`, {params: {category_id: categoryId}})
}

const getBrand = (brandId: number) => {
  return http.get(`${BASE_URL}/brand`, {params: {brand_id: brandId}})
}

const getAllBrands = () => {
  return http.get(`${BASE_URL}/brand/all`)
}

const createBrand = (data: ProductBrand) => {
  return http.post(`${BASE_URL}/brand`, data)
}

const updateBrand = (data: ProductBrand) => {
  return http.put(`${BASE_URL}/brand`, data, {params: {brand_id: data.brand_id}})
}

const updateBrandSelective = (data: ProductBrand) => {
  return http.patch(`${BASE_URL}/brand`, data, {
    params: {
      brand_id: data.brand_id,
    },
  })
}

const updateBrandImage = (data: FileUploadInterface, brandId: number) => {
  return http.put(`${BASE_URL}/brand/image`, data, {params: {brand_id: brandId}})
}

const uploadTradeTurnover = (month: number, title: string, planType: string, file: string, fileName: string) => {
  return http.post(
    `${BASE_URL}/trade_turnover/upload`,
    {file},
    {
      params: {
        month,
        title,
        plan_type: planType,
        file_name: fileName,
      },
    },
  )
}

const getTradeTurnoverReports = (startDate: string, endDate: string, planType: string) => {
  return `${BASE_URL}/trade_turnover/report?start_date=${startDate}&end_date=${endDate}&plan_type=${planType}`
}

const getTradeTurnoverReportSample = () => {
  return `${BASE_URL}/trade_turnover/sample`
}

const getTradeTurnoverItems = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/trade_turnover/items`, {params: {page, size, ...filterData}})
}

const getTradeInTurnoverItems = () => {
  return http.get(`${BASE_URL}/trade_turnover/items`)
}

const deleteBrand = (brandId: number) => {
  return http.delete(`${BASE_URL}/brand`, {params: {brand_id: brandId}})
}

const getModelStockList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/model-stock/items-v2`, {params: {page, size, ...filterData}})
}

const getAllModelStocks = () => {
  return http.get(`${BASE_URL}/model-stock/all`)
}

const getModelStock = (modelStockId: number) => {
  return http.get(`${BASE_URL}/model-stock`, {params: {model_stock_id: modelStockId}})
}

const createModelStock = (data: ProductMainInformationCreate) => {
  return http.post(`${BASE_URL}/model-stock`, data)
}

const updateModelStock = (data: ProductMainInformationCreate) => {
  return http.put(`${BASE_URL}/model-stock`, data, {params: {model_stock_id: data.model_stock_id}})
}

const updateModelStockSelective = (data: ProductMainInformationCreate) => {
  return http.patch(`${BASE_URL}/model-stock`, data, {
    params: {
      model_stock_id: data.model_stock_id,
    },
  })
}

const deleteModelStock = (modelStockId: number) => {
  return http.delete(`${BASE_URL}/model-stock`, {params: {model_stock_id: modelStockId}})
}

const publishModelStock = (modelStockId: number, isPublished: boolean) => {
  return http.patch(`${BASE_URL}/model-stock`, {is_published: isPublished}, {params: {model_stock_id: modelStockId}})
}

const changeAvailabilityModelStockOnline = (modelStockId: number, isAvailable: boolean) => {
  return http.patch(
    `${BASE_URL}/model-stock`,
    {is_online_published: isAvailable},
    {
      params: {model_stock_id: modelStockId},
    },
  )
}
const changePreorderModelStockOnline = (modelStockId: number, isAvailable: boolean) => {
  return http.put(`${BASE_URL}/model-stock`, {pre_order: isAvailable, model_stock_id: modelStockId})
}

const getModelStockWarehouseRelation = (cityId: number, channelId: number, modelStockId: number) => {
  return http.get(`${BASE_URL}/model-stock-warehouse`, {
    params: {
      city_id: cityId,
      channel_id: channelId,
      model_stock_id: modelStockId,
    },
  })
}

const getCheckedStatusOfAllWarehousesInsideChannel = (modelStockId: number) => {
  return http.get(`${BASE_URL}/model-stock-warehouse/channel-warehouse`, {params: {model_stock_id: modelStockId}})
}

const getCheckedStatusOfAllWarehousesInsideCity = (modelStockId: number, channelId: number) => {
  return http.get(`${BASE_URL}/model-stock-warehouse/city-warehouse`, {
    params: {
      model_stock_id: modelStockId,
      channel_id: channelId,
    },
  })
}

const bindModelStockToWarehouse = (data: ProductWarehouseRelation) => {
  return http.post(`${BASE_URL}/model-stock-warehouse/warehouse`, data)
}

const deleteModelStockWarehouseRelation = (data: ProductWarehouseRelation) => {
  return http.delete(`${BASE_URL}/model-stock-warehouse/warehouse`, {
    params: {
      model_stock_id: data.model_stock_id,
      warehouse_id: data.warehouse_id,
      channel_id: data.channel_id,
      city_id: data.city_id,
    },
  })
}

const bindModelStockToCityAllWarehouses = (data: ProductCityRelation) => {
  return http.post(`${BASE_URL}/model-stock-warehouse/city`, data)
}

const unBindModelStockToCityAllWarehouses = (data: ProductCityRelation) => {
  return http.delete(`${BASE_URL}/model-stock-warehouse/city`, {
    params: {
      model_stock_id: data.model_stock_id,
      city_id: data.city_id,
      channel_id: data.channel_id,
    },
  })
}

const bindModelStockToChannelAllWarehouses = (data: ProductChannelRelation) => {
  return http.post(`${BASE_URL}/model-stock-warehouse/channel`, data)
}

const unBindModelStockToChannelAllWarehouses = (data: ProductChannelRelation) => {
  return http.delete(`${BASE_URL}/model-stock-warehouse/channel`, {
    params: {
      model_stock_id: data.model_stock_id,
      channel_id: data.channel_id,
    },
  })
}

const getWarehouseRelation = (cityId: number, channelId: number) => {
  return http.get(`${BASE_URL}/channel-warehouse`, {
    params: {
      city_id: cityId,
      channel_id: channelId,
    },
  })
}

const bindWarehouseRelation = (data: WarehouseRelation) => {
  return http.post(`${BASE_URL}/channel-warehouse/warehouse`, data)
}

const unbindWarehouseRelation = (data: WarehouseRelation) => {
  return http.delete(`${BASE_URL}/channel-warehouse/warehouse`, {
    params: {
      warehouse_id: data.warehouse_id,
      channel_id: data.channel_id,
    },
  })
}

const getCheckedStatusStatusOfAllWarehousesCity = (channel_id: number) => {
  return http.get(`${BASE_URL}/channel-warehouse/city-warehouse`, {
    params: {
      channel_id: channel_id,
    },
  })
}

const getCheckedStatusOfAllWarehousesChannel = () => {
  return http.get(`${BASE_URL}/channel-warehouse/channel-warehouse`)
}

const bindWarehouseToChannel = (data: WarehouseChannelRelation) => {
  return http.post(`${BASE_URL}/channel-warehouse/channel`, data)
}

const unbindWarehouseToChannel = (data: WarehouseCityRelation) => {
  return http.delete(`${BASE_URL}/channel-warehouse/channel`, {
    params: {
      channel_id: data.channel_id,
      city_id: data.city_id,
    },
  })
}

const deleteWarehouseRelation = (data: WarehouseRelation) => {
  return http.delete(`${BASE_URL}/channel-warehouse`, {
    params: {
      warehouse_id: data.warehouse_id,
      channel_id: data.channel_id,
    },
  })
}

const bindAllWarehousesInsideCity = (data: WarehouseCityRelation) => {
  return http.post(`${BASE_URL}/channel-warehouse/city`, data)
}

const unbindAllWarehousesInsideCity = (data: WarehouseCityRelation) => {
  return http.delete(`${BASE_URL}/channel-warehouse/city`, {
    params: {
      city_id: data.city_id,
      channel_id: data.channel_id,
    },
  })
}

const bindAllWarehousesInsideChannel = (data: WarehouseChannelRelation) => {
  return http.post(`${BASE_URL}/channel-warehouse/channel`, data)
}

const unbindAllWarehousesInsideChannel = (data: WarehouseChannelRelation) => {
  return http.delete(`${BASE_URL}/channel-warehouse/channel`, {
    params: {
      channel_id: data.channel_id,
    },
  })
}

const getAllCities = () => {
  return http.get(`${BASE_URL}/city/all`)
}

const getAllBank = () => {
  return http.get(`${BASE_URL}/bank/all`)
}

const getCitiesWithExistingWarehouses = () => {
  return http.get(`${BASE_URL}/city/exist-warehouse`)
}

const getAllModelStockImages = (modelStockId?: number) => {
  return http.get(`${BASE_URL}/image/all`, {params: {model_stock_id: modelStockId}})
}

const getModelStockImagesList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/image/items`, {params: {page, size, ...filterData}})
}

const getModelStockImage = (imageId: number) => {
  return http.get(`${BASE_URL}/image`, {params: {image_id: imageId}})
}

const createModelStockImage = (data: ProductImage[]) => {
  return http.post(`${BASE_URL}/image`, data)
}

const updateModelStockImage = (data: ProductImage[], modelStockId: number) => {
  return http.put(`${BASE_URL}/image`, data, {params: {model_stock_id: modelStockId}})
}

const changeOrderOfModelStockImage = (data: ProductImage[], modelStockId: number) => {
  return http.put(`${BASE_URL}/image/swap-images`, data, {params: {model_stock_id: modelStockId}})
}

const deleteModelStockImage = (imageId: number) => {
  return http.delete(`${BASE_URL}/image`, {params: {image_id: imageId}})
}

const getUserWarehouseRelations = (cityId: number, channelId: number, userId: number) => {
  return http.get(`${BASE_URL}/warehouse-user`, {
    params: {
      user_id: userId,
      city_id: cityId,
      channel_id: channelId,
    },
  })
}

const bindUserToWarehouse = (data: UserWarehouseRelation) => {
  return http.post(`${BASE_URL}/warehouse-user/warehouse`, data)
}

const unbindUserToWarehouse = (data: UserWarehouseRelation) => {
  return http.delete(`${BASE_URL}/warehouse-user/warehouse`, {
    params: {
      user_id: data.user_id,
      warehouse_id: data.warehouse_id,
      channel_id: data.channel_id,
    },
  })
}

const bindUserAllWarehousesInsideCity = (data: UserWarehouseCityRelation) => {
  return http.post(`${BASE_URL}/warehouse-user/city`, data)
}

const unbindUserAllWarehousesInsideCity = (data: UserWarehouseCityRelation) => {
  return http.delete(`${BASE_URL}/warehouse-user/city`, {
    params: {
      user_id: data.user_id,
      channel_id: data.channel_id,
      city_id: data.city_id,
    },
  })
}

const bindUserAllWarehousesInsideChannel = (data: UserWarehouseChannelRelation) => {
  return http.post(`${BASE_URL}/warehouse-user/channel`, data)
}

const unbindUserAllWarehousesInsideChannel = (data: UserWarehouseChannelRelation) => {
  return http.delete(`${BASE_URL}/warehouse-user/channel`, {
    params: {
      user_id: data.user_id,
      channel_id: data.channel_id,
    },
  })
}

const getCheckedAllWarehousesInsideChannelUser = (userId: number) => {
  return http.get(`${BASE_URL}/warehouse-user/channel-warehouse`, {
    params: {
      user_id: userId,
    },
  })
}

const getCheckedAllWarehousesInsideCityUser = (userId: number, channelId: number) => {
  return http.get(`${BASE_URL}/warehouse-user/city-warehouse`, {
    params: {
      user_id: userId,
      channel_id: channelId,
    },
  })
}

const getCheckedChannelsInsideWarehouse = (warehouseId: string) => {
  return http.get(`${BASE_URL}/channel-warehouse/checked-channels`, {params: {warehouse_id: warehouseId}})
}

// Функционал "С этим товаром также покупают"
const getSimilarProductList = (modelStockId: number, page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/model-stock-model-stock/items`, {
    params: {
      from_model_stock_id: modelStockId,
      page,
      size,
      ...filterData,
    },
  })
}

const getAllSimilarProducts = (modelStockId: number) => {
  return http.get(`${BASE_URL}/model-stock-model-stock/all`, {params: {from_model_stock_id: modelStockId}})
}

const getSimilarProduct = (modelStockId: number) => {
  return http.get(`${BASE_URL}/model-stock-model-stock`, {params: {from_model_stock_id: modelStockId}})
}

const createSimilarProduct = (data: SimilarProduct) => {
  return http.post(`${BASE_URL}/model-stock-model-stock`, data)
}

const updateSimilarProduct = (data: SimilarProduct) => {
  return http.put(`${BASE_URL}/model-stock-model-stock`, data, {
    params: {from_model_stock_id: data.from_model_stock_id},
  })
}

const deleteSimilarProduct = (modelStockId: number) => {
  return http.delete(`${BASE_URL}/model-stock-model-stock`, {params: {from_model_stock_id: modelStockId}})
}

const getAllServiceTypes = () => {
  return http.get(`${BASE_URL}/service-type/all`)
}

const getServiceTypeList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/service-type/items`, {params: {page, size, ...filterData}})
}

const getServiceType = (service_type_id: number) => {
  return http.get(`${BASE_URL}/service-type`, {params: {service_type_id: service_type_id}})
}

const updateServiceType = (data: ServiceType) => {
  return http.put(`${BASE_URL}/service-type`, data, {params: {service_type_id: data.service_type_id}})
}

const getAllServices = () => {
  return http.get(`${BASE_URL}/service/all`)
}

const getServicesList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/service/items`, {params: {page, size, ...filterData}})
}

const getService = (serviceId: number) => {
  return http.get(`${BASE_URL}/service`, {params: {service_id: serviceId}})
}

const updateService = (data: Service) => {
  return http.put(`${BASE_URL}/service`, data, {params: {service_id: data.service_id}})
}

const updateServiceCostPrice = (data: ServiceCostPrice, serviceId: number) => {
  return http.patch(`${BASE_URL}/service/cost_price`, data, {
    params: {
      service_id: serviceId,
    },
  })
}

const updateServiceSelective = (data: Service) => {
  return http.patch(`${BASE_URL}/service`, data, {
    params: {
      service_id: data.service_id,
    },
  })
}

const publishService = (serviceId: number, publish: boolean) => {
  return http.put(`${BASE_URL}/service/publish`, null, {params: {service_id: serviceId, approved_to_public: publish}})
}

const getAllServiceGroups = () => {
  return http.get(`${BASE_URL}/service-group/all`)
}

const getServiceGroupsList = (page: number, size: number, filterData?: object) => {
  return http.get(`${BASE_URL}/service-group/items`, {params: {page, size, ...filterData}})
}

const getServiceGroup = (serviceGroupId: number) => {
  return http.get(`${BASE_URL}/service-group`, {params: {service_group_id: serviceGroupId}})
}

const createServiceGroup = (data: ServiceGroup) => {
  return http.post(`${BASE_URL}/service-group`, data, {params: {service_group_id: data.service_group_id}})
}

const updateServiceGroup = (data: ServiceGroup) => {
  return http.put(`${BASE_URL}/service-group`, data, {params: {service_group_id: data.service_group_id}})
}

const deleteServiceGroup = (serviceGroupId: number) => {
  return http.delete(`${BASE_URL}/service-group`, {params: {service_group_id: serviceGroupId}})
}

const getUserReport = (): PromiseResponse<UserReport[]> => {
  return http.get(`${BASE_URL}/report/users`)
}

const getChannelsReport = (): PromiseResponse<ChannelReport[]> => {
  return http.get(`${BASE_URL}/report/channels`)
}

const getWarehousesReport = (): PromiseResponse<WarehouseReport[]> => {
  return http.get(`${BASE_URL}/report/warehouses`)
}

const getCitiesReport = (): PromiseResponse<CityReport[]> => {
  return http.get(`${BASE_URL}/report/cities`)
}

const createCategoryBrand = (data: CategoryBrand): PromiseResponse<CategoryBrand> => {
  return http.post(`${BASE_URL}/category-brand/categories`, data)
}

const deleteCategoryBrand = (categoryId: number, brandId: number) => {
  return http.delete(`${BASE_URL}/category-brand`, {
    params: {
      category_id: categoryId,
      brand_id: brandId,
    },
  })
}

const getAllInstallmentPercents = (): PromiseResponse<InstallmentPercent[]> => {
  return http.get(`${BASE_URL}/installment-percent/all`)
}

const getInstallmentPercentList = (
  page: number,
  size: number,
  filterData?: object,
): PromiseResponse<InstallmentPercent[]> => {
  return http.get(`${BASE_URL}/installment-percent/items`, {params: {page, size, ...filterData}})
}

const getInstallmentPercent = (installmentPercentId: number) => {
  return http.get(`${BASE_URL}/installment-percent`, {params: {installment_percent_id: installmentPercentId}})
}

const createInstallmentPercent = (data: InstallmentPercent) => {
  return http.post(`${BASE_URL}/installment-percent`, data)
}

const updateInstallmentPercent = (data: InstallmentPercent) => {
  return http.put(`${BASE_URL}/installment-percent`, data, {
    params: {installment_percent_id: data.instalment_percent_id},
  })
}

const deleteInstallmentPercent = (installmentPercentId: number) => {
  return http.delete(`${BASE_URL}/installment-percent`, {params: {installment_percent_id: installmentPercentId}})
}

const getAllTelecomOperators = () => {
  return http.get(`${BASE_URL}/telecom-operator/all`)
}

const getTelecomOperator = (telecomOperatorId: number) => {
  return http.get(`${BASE_URL}/telecom-operator`, {
    params: {
      telecom_operator_id: telecomOperatorId,
    },
  })
}

const createTelecomOperator = (data: TelecomOperator) => {
  return http.post(`${BASE_URL}/telecom-operator`, data)
}

const deleteTelecomOperator = (telecomOperatorId: number) => {
  return http.delete(`${BASE_URL}/telecom-operator`, {
    params: {
      telecom_operator_id: telecomOperatorId,
    },
  })
}

const updateTelecomOperator = (data: TelecomOperator, telecomOperatorId: number) => {
  return http.patch(`${BASE_URL}/telecom-operator`, data, {
    params: {
      telecom_operator_id: telecomOperatorId,
    },
  })
}

const getAllCardTypes = () => {
  return http.get(`${BASE_URL}/card-type/all`)
}

const getCardTypesList = (page: number, size: number, filterData?: object): PromiseTableResponse<Array<CardType>> => {
  return http.get(`${BASE_URL}/card-type/items`, {params: {page, size, ...filterData}})
}

const getCardType = (cardTypeId: number) => {
  return http.get(`${BASE_URL}/card-type`, {
    params: {
      card_type_id: cardTypeId,
    },
  })
}

const createCardType = (data: CardType) => {
  return http.post(`${BASE_URL}/card-type`, data)
}

const updateCardType = (data: CardType) => {
  return http.put(`${BASE_URL}/card-type`, data, {
    params: {
      card_type_id: data.card_type_id,
    },
  })
}

const deleteCardType = (cardTypeId: number) => {
  return http.delete(`${BASE_URL}/card-type`, {
    params: {
      card_type_id: cardTypeId,
    },
  })
}

const getOnlineOrders = (page: number, size: number, filterData?: object): PromiseResponse<Order[]> => {
  return http.get(`${BASE_URL}/common/order/all-admin-orders`, {params: {page, size, ...filterData}})
}

const getOrderDetails = (orderId: number): PromiseResponse<OrderDetail> => {
  return http.get(`${BASE_URL}/common/order`, {
    params: {
      order_id: orderId,
    },
  })
}

const getCustomerInfoByOrderSession = (orderSession: string): PromiseResponse<OrderCustomerInfo> => {
  return http.get(`${BASE_URL}/customer/order`, {
    params: {
      order_session: orderSession,
    },
  })
}

const getOrderProductsByOrderSession = (orderSession: string): PromiseResponse<OrderProduct[]> => {
  return http.get(`${BASE_URL}/common/order-product/order`, {
    params: {
      order_session: orderSession,
    },
  })
}

const getOrderServicesByOrderSession = (orderSession: string) => {
  return http.get(`${BASE_URL}/common/order-service/order`, {
    params: {
      order_session: orderSession,
    },
  })
}

const getOrderTariffsByOrderSession = (orderSession: string): PromiseResponse<any> => {
  return http.get(`${BASE_URL}/common/telecom-tariff/order`, {
    params: {
      order_session: orderSession,
    },
  })
}

const getOrderHistoryByOrderSession = (orderSession: string): PromiseResponse<OrderHistory[]> => {
  return http.get(`${BASE_URL}/offline/order-history`, {
    headers: {
      "order-session": orderSession,
    },
  })
}
const deleteOrderStatus = (orderSession: string, orderHistoryId: number) => {
  return http.post(`${BASE_URL}/common/order/delete-status`, orderHistoryId, {
    params: {
      order_session: orderSession,
      order_history_id: orderHistoryId,
    },
  })
}

const getOrderPaymentInfoByOrderSession = (orderSession: string): PromiseResponse<OrderPaymentInfo> => {
  return http.get(`${BASE_URL}/offline/order-payment`, {
    headers: {
      "order-session": orderSession,
    },
  })
}

const getOrderAdditionalInfoByOrderSession = (orderSession: string): PromiseResponse<OrderAdditionalInfo> => {
  return http.get(`${BASE_URL}/common/order/additional-info`, {
    params: {
      order_session: orderSession,
    },
  })
}

const getOrderDeliveryInfoByOrderSession = (orderSession: string) => {
  return http.get(`${BASE_URL}/common/order-delivery/order`, {
    params: {
      order_session: orderSession,
    },
  })
}

const getOrderCustomerAndShipmentInformation = (orderSession: string) => {
  return http.get(`${BASE_URL}/common/order/supplier-shipment`, {
    headers: {
      "order-session": orderSession,
    },
  })
}

const getOrderShipmentsMap = (orderId: string) => {
  return http.post(`${BASE_URL}/product/shipments-map`, null, {
    params: {
      order_id: orderId,
    },
  })
}

const cancelOrder = (orderSession: string): PromiseResponse<any> => {
  return http.post(`${BASE_URL}/common/order/cancel`, null, {
    params: {
      order_session: orderSession,
    },
  })
}

const refundPayboxMoney = (orderSession: string): PromiseResponse<any> => {
  return http.post(`${BASE_URL}/offline/order-payment/refund-paybox-payment`, null, {
    params: {
      order_session: orderSession,
    },
  })
}

const getAllDeliveryTypes = () => {
  return http.get(`${BASE_URL}/common/order-delivery/delivery-types`)
}

const beelineChangePhoneNumber = (data: BeelineChangePhoneNumber): PromiseResponse<any> => {
  return http.post(`${BASE_URL}/common/order-service/beeline/change-phone`, data)
}

const beelineSockConnection = (data: {order_service_id: number}) => {
  return http.post(`${BASE_URL}/common/order-service/beeline/soc-connection`, data)
}

const beelineServiceConnection = (data: {order_service_id: number}) => {
  return http.post(`${BASE_URL}/common/order-service/beeline/tariff-connection`, data)
}

const connectTele2Tariff = (orderServiceId: number) => {
  return http.post(`${BASE_URL}/common/order-service/tele2/contract-connection`, null, {
    params: {
      order_service_id: orderServiceId,
    },
  })
}

const tele2ChangePhoneNumber = (data: Tele2ChangePhoneNumber) => {
  return http.post(`${BASE_URL}/common/order-service/tele2/change-phone`, data)
}

const doAsiaPayment = (orderServiceId: number) => {
  return http.post(`${BASE_URL}/common/order-service/tele2/create-asiapay-payment`, null, {
    params: {
      order_service_id: orderServiceId,
    },
  })
}

const lifeInsuranceConnect = (orderServiceId: number) => {
  return http.post(`${BASE_URL}/common/order-service/life-insurance-connect`, null, {
    params: {
      order_service_id: orderServiceId,
    },
  })
}

const deviceInsuranceConnect = (orderServiceId: number) => {
  return http.post(`${BASE_URL}/common/order-service/device-insurance-connect`, null, {
    params: {
      order_service_id: orderServiceId,
    },
  })
}

const changeOrderStatusOfOrder = (orderSession: string, orderStatusCode: string) => {
  return http.post(`${BASE_URL}/common/order/change-status`, null, {
    params: {
      order_session: orderSession,
      order_status_code: orderStatusCode,
    },
  })
}

const updateOrderComment = (orderComment: string, orderSession: string) => {
  return http.put(
    `${BASE_URL}/common/order/comment`,
    {order_comment: orderComment},
    {
      params: {
        order_session: orderSession,
      },
    },
  )
}

const uploadSamsungReport = (file: string) => {
  return http.post(`${BASE_URL}/samsung/upload-samsung-report`, {file})
}

const getPublicOffers = (page: number, size: number): PromiseTableResponse<PublicOffer[]> => {
  return http.get(`${BASE_URL}/public_offer/get-all-public-offers`, {params: {page, size}})
}

const postPublicOffer = (params: {file_name: string; from_date: string; to_date?: string}, file: {file: string}) => {
  return http.post(`${BASE_URL}/public_offer/upload-public-offer`, file, {params: params})
}
const changePublicOffer = (params: {public_offer_id: number; date_from: string; date_to?: string}) => {
  return http.put(`${BASE_URL}/public_offer/update-public-offer-dates/{file_id}`, null, {params: params})
}
const changeOrderChannelAndSeller = (
  order_id: number,
  data: {code: "channel_id" | "supplier_id" | "shipment_user_id"; value: number; comment: string},
) => {
  return http.put(`${BASE_URL}/common/order/admin`, data, {
    params: {
      order_id: order_id,
    },
  })
}

const syncUsers = () => {
  return http.get(`${BASE_URL}/get-users`)
}

const getDigitalProductsVendors = (): PromiseResponse<Array<IKeyVendor>> => {
  return http.get(`${BASE_URL}/key-vendors/all-vendors`)
}

export const SaleService = {
  getUserReport,
  getChannelsReport,
  getWarehousesReport,
  getCitiesReport,
  getOption,
  getOptionKey,
  getAttribute,
  getAttributeKey,
  getAttributeGroup,
  getColor,
  getModel,
  getBrand,
  getInstallmentPercent,
  getModelStock,
  getModelStockImage,
  getService,
  getServiceType,
  getAttributesList,
  getAttributeKeysList,
  getAttributeGroupsList,
  getBrandList,
  getBrandsByCategory,
  getModelStockList,
  getModelStockImagesList,
  getSimilarProductList,
  getServiceTypeList,
  getServicesList,
  getCardType,
  getInstallmentPercentList,
  getCustomerInfoByOrderSession,
  getOrderProductsByOrderSession,
  getOrderTariffsByOrderSession,
  getOrderHistoryByOrderSession,
  getOrderPaymentInfoByOrderSession,
  getOrderAdditionalInfoByOrderSession,
  getOrderDeliveryInfoByOrderSession,
  getOrderCustomerAndShipmentInformation,
  getOrderServicesByOrderSession,
  getOrderShipmentsMap,
  cancelOrder,
  refundPayboxMoney,
  getAllPaymentStatuses,
  getAllPaymentTypes,
  getAllAttributes,
  getAllAttributeGroups,
  getAllColors,
  getAllModels,
  getAllBrands,
  getAllTemplates,
  getAllOptions,
  getAllOptionKeys,
  getOnlineOrders,
  getAllAttributeKeys,
  getAllModelStocks,
  getAllSimilarProducts,
  getAllTelecomOperators,
  getAllStocks,
  getAllCities,
  getAllCardTypes,
  getAllModelStockImages,
  getAllServices,
  getAllServiceTypes,
  getAllServiceGroups,
  getAllInstallmentPercents,
  getAllDeliveryTypes,
  getAllStores,
  getTemplatesList,
  getTemplate,
  getTemplateByCategory,
  getTemplateByModel,
  getOptionsList,
  getOptionsKeyList,
  getOrderDetails,
  getColorList,
  getStockList,
  getStoreList,
  getServiceGroupsList,
  getCardTypesList,
  getCitiesWithExistingWarehouses,
  getUniqueStockList,
  getModelList,
  getModelStockWarehouseRelation,
  getWarehouseRelation,
  getSimilarProduct,
  getTelecomOperator,
  getUserWarehouseRelations,
  getServiceGroup,
  getCheckedStatusOfAllWarehousesInsideChannel,
  getCheckedStatusOfAllWarehousesInsideCity,
  getCheckedStatusOfAllWarehousesChannel,
  getCheckedStatusStatusOfAllWarehousesCity,
  getCheckedAllWarehousesInsideChannelUser,
  getCheckedAllWarehousesInsideCityUser,
  getTradeTurnoverReportSample,
  getCheckedChannelsInsideWarehouse,
  createAttribute,
  createAttributeKey,
  createAttributeGroup,
  createOption,
  createTemplate,
  createTemplateAttributeKey,
  createTemplateOptionKey,
  createCategoryBrand,
  createOptionKey,
  createColor,
  createModel,
  createBrand,
  createModelStock,
  createModelStockImage,
  createSimilarProduct,
  createInstallmentPercent,
  createTelecomOperator,
  createServiceGroup,
  changeOrderStatusOfOrder,
  createCardType,
  updateAttribute,
  updateAttributeKey,
  updateAttributeGroup,
  updateColor,
  updateCardType,
  updateOption,
  updateOptionKey,
  updateTemplate,
  updateModel,
  updateModelStock,
  updateModelStockImage,
  updateBrand,
  updateBrandImage,
  updateSimilarProduct,
  updateServiceType,
  updateService,
  updateServiceGroup,
  updateServiceCostPrice,
  updateInstallmentPercent,
  updateTelecomOperator,
  updateBrandSelective,
  updateColorSelective,
  updateOptionKeySelective,
  updateAttributeKeySelective,
  updateAttributeSelective,
  updateModelSelective,
  updateServiceSelective,
  updateStoreSelective,
  updateModelStockSelective,
  deleteAttribute,
  deleteAttributeKey,
  deleteAttributeGroup,
  deleteColor,
  deleteTemplate,
  deleteOption,
  deleteOptionKey,
  deleteModel,
  deleteBrand,
  deleteCategoryBrand,
  deleteCardType,
  deleteServiceGroup,
  deleteModelStock,
  deleteModelStockImage,
  deleteTelecomOperator,
  deleteModelStockWarehouseRelation,
  deleteWarehouseRelation,
  deleteInstallmentPercent,
  deleteSimilarProduct,
  deleteOrderStatus,
  bindModelStockToWarehouse,
  bindWarehouseRelation,
  bindModelStockToCityAllWarehouses,
  bindModelStockToChannelAllWarehouses,
  bindWarehouseToChannel,
  bindAllWarehousesInsideChannel,
  bindAllWarehousesInsideCity,
  bindUserToWarehouse,
  bindUserAllWarehousesInsideChannel,
  bindUserAllWarehousesInsideCity,
  unbindWarehouseRelation,
  unBindModelStockToChannelAllWarehouses,
  unBindModelStockToCityAllWarehouses,
  unbindAllWarehousesInsideChannel,
  unbindAllWarehousesInsideCity,
  unbindUserToWarehouse,
  unbindUserAllWarehousesInsideChannel,
  unbindUserAllWarehousesInsideCity,
  changeOrderOfModelStockImage,
  publishModelStock,
  publishService,
  publishStore,
  updateStoreSchedule,
  changeAvailabilityModelStockOnline,
  changePreorderModelStockOnline,
  transferCategory,
  beelineSockConnection,
  beelineServiceConnection,
  beelineChangePhoneNumber,
  connectTele2Tariff,
  tele2ChangePhoneNumber,
  doAsiaPayment,
  lifeInsuranceConnect,
  deviceInsuranceConnect,
  updateOrderComment,
  uploadTradeTurnover,
  getTradeTurnoverItems,
  getTradeTurnoverReports,
  uploadSamsungReport,
  getPublicOffers,
  postPublicOffer,
  changePublicOffer,
  changeOrderChannelAndSeller,
  syncUsers,
  getAllBank,
  getDigitalProductsVendors,
}
